<template>
  <div id="dashboard-content">
    <div class="container">
      <div class="d-flex align-items-center mb-4">
        <h4 class="mr-auto">Cetak Bukti</h4>
        <nav>
          <ol class="breadcrumb p-0 bg-transparent">
            <li class="breadcrumb-item p-0 m-0">
              <router-link to="/user/pembinaan" class="text-muted">
                Daftar Penilaian Buku
              </router-link>
            </li>
            <li class="breadcrumb-item active text-dark" aria-current="page">
              Cetak Bukti
            </li>
          </ol>
        </nav>
      </div>

      <div class="row">
        <div class="col-lg-12 overflow-auto">
          <!-- Non Printable -->
          <vue-html2pdf
            :show-layout="true"
            :float-layout="false"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1200"
            filename="Bukti Cetak"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="650px"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
            class="non-printable d-flex justify-content-center"
          >
            <section slot="pdf-content">
              <div ref="content" class="my-4">
                <div class="row justify-content-center">
                  <div class="col-12">
                    <div
                      class="card p-4 position-relative"
                      style="background-color: white;"
                    >
                      <div class="p-3 text-center" style="background: #F6FCFF;">
                        <h5 class="w-75 mx-auto mb-0">
                          {{ bookPublisher.title }}
                        </h5>
                      </div>
                      <div class="row mt-4">
                        <div class="col-lg-6 mb-3">
                          <div class="text-muted">Kode Buku</div>
                          <div>{{ bookPublisher.code }}</div>
                        </div>
                        <div class="col-lg-6 mb-3">
                          <div class="text-muted">ISBN</div>
                          <div>{{ bookPublisher.isbn }}</div>
                        </div>
                        <div class="col-lg-6 mb-3">
                          <div class="text-muted">Tipe Buku</div>
                          <div>{{ bookPublisher.book_type }}</div>
                        </div>
                        <div class="col-lg-6 mb-3">
                          <div class="text-muted">Tahun Terbit</div>
                          <div>{{ bookPublisher.publication_year }}</div>
                        </div>
                        <div class="col-lg-6 mb-3">
                          <div class="text-muted">Jenjang</div>
                          <div>{{ bookPublisher.level }}</div>
                        </div>
                        <div class="col-lg-6 mb-3">
                          <div class="text-muted">Penerbit</div>
                          <div>{{ publisher.name }}</div>
                        </div>
                        <div class="col-lg-6 mb-3">
                          <div class="text-muted">Kontak</div>
                          <div>{{ publisher.phone }}</div>
                        </div>
                      </div>

                      <div
                        class="p-2 rounded text-muted mt-3"
                        style="background: #F9FBFD;"
                      >
                        <h6>Kepada</h6>
                        <p class="m-0">
                          Sekretariat Panitia Penilaian Buku Nonteks Pelajaran
                          Pusat Perbukuan, BSKAP, Kemendikdasmen. Kompleks
                          Kemendikdasmen Gedung D Lantai 1 JL. R.S.
                          Fatmawati, Cipete, Jakarta 12410
                        </p>
                      </div>

                      <div class="row align-items-center mt-4">
                        <div class="col-1 p-0"></div>
                        <div class="col-10 p-0">
                          <div style="border: 1px dashed grey;"></div>
                        </div>
                        <div class="col-1 p-0"></div>
                      </div>
                      <div class="mt-4 text-center">
                        <div class="text-muted small mb-2">
                          Scan QR dibawah ini untuk menandai buku sudah
                          diterima.
                        </div>
                        <img :src="qrCode" alt="Kode QR" class="mb-3" />
                        <h6 class="text-muted">
                          Pusat Perbukuan, BSKAP
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </vue-html2pdf>

          <!-- Printable -->
          <vue-html2pdf
            :show-layout="true"
            :float-layout="false"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1200"
            filename="Bukti Cetak"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="650px"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
            class="printable"
          >
            <section slot="pdf-content">
              <div ref="content" class="cert mx-5 my-5">
                <div class="row justify-content-center">
                  <div class="col-lg-12">
                    <div
                      class="card p-4 position-relative"
                      style="background-color: white;"
                    >
                      <div class="p-3 text-center" style="background: #F6FCFF;">
                        <h5 class="w-75 mx-auto mb-0">
                          {{ bookPublisher.title }}
                        </h5>
                      </div>
                      <div class="row mt-4">
                        <div class="col-lg-6 mb-3">
                          <div class="text-muted">Kode Buku</div>
                          <div>{{ bookPublisher.code }}</div>
                        </div>
                        <div class="col-lg-6 mb-3">
                          <div class="text-muted">ISBN</div>
                          <div>{{ bookPublisher.isbn }}</div>
                        </div>
                        <div class="col-lg-6 mb-3">
                          <div class="text-muted">Tipe Buku</div>
                          <div>{{ bookPublisher.book_type }}</div>
                        </div>
                        <div class="col-lg-6 mb-3">
                          <div class="text-muted">Tahun Terbit</div>
                          <div>{{ bookPublisher.publication_year }}</div>
                        </div>
                        <div class="col-lg-6 mb-3">
                          <div class="text-muted">Jenjang</div>
                          <div>{{ bookPublisher.level }}</div>
                        </div>
                        <div class="col-lg-6 mb-3">
                          <div class="text-muted">Penerbit</div>
                          <div>{{ publisher.name }}</div>
                        </div>
                        <div class="col-lg-6 mb-3">
                          <div class="text-muted">Kontak</div>
                          <div>{{ publisher.phone }}</div>
                        </div>
                      </div>

                      <div
                        class="p-2 rounded text-muted mt-3"
                        style="background: #F9FBFD;"
                      >
                        <h6>Kepada</h6>
                        <p class="m-0">
                          Sekretariat Panitia Penilaian Buku Nonteks Pelajaran
                          Pusat Perbukuan, BSKAP, Kemendikdasmen. Kompleks
                          Kemendikdasmen Gedung D Lantai 1 JL. R.S.
                          Fatmawati, Cipete, Jakarta 12410
                        </p>
                      </div>

                      <div class="row align-items-center mt-4">
                        <div class="col-1 p-0"></div>
                        <div class="col-10 p-0">
                          <div style="border: 1px dashed grey;"></div>
                        </div>
                        <div class="col-1 p-0"></div>
                      </div>
                      <div class="mt-4 text-center">
                        <div class="text-muted small mb-2">
                          Scan QR dibawah ini untuk menandai buku sudah
                          diterima.
                        </div>
                        <img :src="qrCode" alt="Kode QR" class="mb-3" />
                        <h6 class="text-muted">
                          Pusat Perbukuan, BSKAP
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </vue-html2pdf>
        </div>
      </div>

      <div class="text-center mt-1">
        <button @click="generatePDF()" class="btn btn-primary">
          <i class="fa fa-print mr-1"></i>
          <span>Cetak Bukti </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
import moment from "moment";
moment.locale("id");

export default {
  name: "Cetak Izin Buku",
  data() {
    return {
      showAll: false,
      publisher: {
        name: "",
        phone: "",
      },
    };
  },
  components: {
    VueHtml2pdf,
  },
  computed: {
    ...mapState(["bookPublisher"]),
    qrCode: function() {
      let code = this.bookPublisher.code;
      let link = `https://penilaian-buku.cloudapp.web.id/api/penilaian/book/confirm/${code}`;
      return `https://api.qrserver.com/v1/create-qr-code/?size=140x140&data=${link}`;
    },
  },
  methods: {
    ...mapActions(["fetchBookByPublisher", "fetchPublisherProfile"]),
    parseDate(date) {
      return moment(date).format("LLLL");
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
  created() {
    const id = this.$route.query.id;
    this.fetchBookByPublisher(id);
    this.fetchPublisherProfile().then((res) => {
      this.publisher.name = res.name;
      this.publisher.phone = res.phone;
    });
  },
  mounted() {
    scrollTo({ top: "0", behavior: "smooth" });
  },
};
</script>

<style scoped>
@media print {
  .non-printable {
    display: none;
  }
}
@media screen {
  .printable {
    display: none;
  }
}
</style>
